import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import { DownloadEpostSignature } from '~/gatsby-theme-docz/components/DownloadEpostSignature';
import { Paragraph, StrongText } from '@entur/typography';
import { colors } from '@entur/tokens';
import EmailBilde from './Email_signatur.png';
import './index.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageHeader mdxType="PageHeader"></PageHeader>
    <p>{`For å endre din signatur i Outlook, gå til: `}<strong parentName="p">{`Fil > Alternativer > E-post > Signaturer > Ny > Gi navn > Lagre.`}</strong></p>
    <DownloadEpostSignature mdxType="DownloadEpostSignature"></DownloadEpostSignature>
    <img src={EmailBilde} width="100%" alt="Illustrerer bruk av Entur sin epost-signatur i slutten av en epost" />
    <div className="epost-card" style={{
      padding: '1.5rem',
      marginTop: '3rem'
    }}>
  <StrongText mdxType="StrongText">Oppsett</StrongText>
  <Paragraph margin="none" mdxType="Paragraph">
    Skrifttype: Arial <br />
    Størrelse: 11 pt navn i bold / 9 pt brødtekst <br />
    Farge: Blue #181C56
  </Paragraph>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      